"use client";

import { CacheProvider } from "@chakra-ui/next-js";
import AppTheme from "../global-theme/AppTheme";
import { ChakraProvider } from "@/components/ChakraUiManager";
import React, { ReactNode, useContext, useState, useEffect } from "react";
import { Toast } from "@/components/common";
import { store } from "@/store";
import { Provider as ReduxProvider, useDispatch } from "react-redux";
import TimeoutHandler from "@/components/common/TimeoutHandler/TimeoutHandler";
import UnsavedChangesProvider from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import Head from "next/head";

export type UserInfo = {
  loading: boolean;
  signedIn: boolean;
  firstName: string;
  lastName: string;
  email: string;
  organization: number;
  fastProfile: any;
  superUser: boolean;
};

let defaultUserContext: {
  user: UserInfo;
  setUser: (user: UserInfo) => void;
} = {
  user: {
    loading: true,
    signedIn: false,
    firstName: "",
    lastName: "",
    email: "",
    organization: 0,
    fastProfile: {},
    superUser: false,
  },
  setUser: (user) => {},
};
export const UserContext = React.createContext(defaultUserContext);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState(defaultUserContext.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const f = async () => {
      try {
        const response = await fetch("/console/my/account");
        const responseJson = await response.json();

        const responseFastProfile = await fetch("/console/my/fastProfile");
        const responseFastProfileJson =
          (await responseFastProfile.json()) || {};

        if (response.status === 403) {
          setUser((prevState) => ({
            ...prevState,
            loading: false,
          }));
        } else if (responseJson) {
          setUser({
            loading: false,
            signedIn: true,
            ...responseJson,
            fastProfile: { ...responseFastProfileJson },
          });
        }
      } catch (err) {
        //Catching 401 error
        //Resetting user loading state to false
        setUser((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    };

    f();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserInfo = () => {
  const { user, setUser } = useContext(UserContext);
  return { user, setUser };
};

export const useUserAuthenticated = () => {
  const { user } = useContext(UserContext);
  return user.loading == false && user.signedIn == true;
};

const Providers = ({ children }: { children: React.ReactNode }) => {
  /* Chakra's cache provider wraps Emotion's cache provider,
    on dev server all styles are load properly, i.e. our scss styles are loaded after emotion's styles,
    but on prod build the reverse is happening, and emotion is overriding our styles.
    
    Passing a <meta/> element to chakra's <CacheProvider/> as `insertionPoint` prop tells Emotion where to inject it's own styles.
    
    Here we are prepending the <meta/> element on the <head/> so our component styles are loaded after Emotion's styles thus preventing it from overriding our styles 
  */
  const [insertionPoint, setInsertionPoint] = useState<HTMLElement | undefined>(
    undefined,
  );

  useEffect(() => {
    const head = document.querySelector("head");

    const emotionInsertionPoint = document.createElement("meta");
    emotionInsertionPoint.setAttribute("name", "emotion-insertion-point");
    emotionInsertionPoint.setAttribute("content", "");
    head?.prepend(emotionInsertionPoint);
    // This element becomes the top of head's child node <meta name="emotion-insertion-point" content="">
    setInsertionPoint(emotionInsertionPoint);
  }, []);

  if (process.env.NODE_ENV === "development") {
    return (
      <html lang="en">
        <body>
          <ReduxProvider store={store}>
            <UserProvider>
              <UnsavedChangesProvider>
                <CacheProvider>
                  <ChakraProvider
                    theme={AppTheme}
                    toastOptions={{
                      defaultOptions: {
                        duration: 5000,
                        render: (props: any) => <Toast {...props} />,
                      },
                    }}
                  >
                    {children}
                  </ChakraProvider>
                </CacheProvider>
              </UnsavedChangesProvider>
              <TimeoutHandler />
            </UserProvider>
          </ReduxProvider>
          <div id="appFooter" className="app-footer"></div>
          <footer id="appPortal"></footer>
        </body>
      </html>
    );
  }

  // Render this if not using dev server (static builds)
  return insertionPoint ? (
    <html lang="en">
      <body>
        <ReduxProvider store={store}>
          <UserProvider>
            <UnsavedChangesProvider>
              <CacheProvider insertionPoint={insertionPoint}>
                <ChakraProvider
                  theme={AppTheme}
                  toastOptions={{
                    defaultOptions: {
                      duration: 5000,
                      render: (props: any) => <Toast {...props} />,
                    },
                  }}
                >
                  {children}
                </ChakraProvider>
              </CacheProvider>
            </UnsavedChangesProvider>
            <TimeoutHandler />
          </UserProvider>
        </ReduxProvider>
        <div id="appFooter" className="app-footer"></div>
        <footer id="appPortal"></footer>
      </body>
    </html>
  ) : null;
};
export default Providers;
